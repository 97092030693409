import React, { useContext } from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  services,
  aboutSection,
  howItWorkInfo,
  testimonials,
} from "../../portfolio";

function Header() {
  const { isDark } = useContext(StyleContext);
  const viewServices = services.display;
  const viewHowItWorkInfo = howItWorkInfo.display;
  const viewAbout = aboutSection.display;
  const viewTestimonials = testimonials.display;

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{ color: "white" }}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewServices && (
            <li>
              <a href="#services">Services</a>
            </li>
          )}
          {viewAbout && (
            <li>
              <a href="#about">About</a>
            </li>
          )}
          {viewHowItWorkInfo && (
            <li>
              <a href="#how-it-works">How We Work</a>
            </li>
          )}
          {viewTestimonials && (
            <li>
              <a href="#testimonials">Testimonials</a>
            </li>
          )}
          <li>
            <a href="#contact">Get in touch</a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
