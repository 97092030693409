import React, { useContext } from "react";
import "./services.scss";
import ServiceCard from "../../components/serviceCard/ServiceCard";
import { services } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import AliceCarousel from "react-alice-carousel";

export default function Services() {
  const { isDark } = useContext(StyleContext);

  if (services.display) {
    return (
      <div id="services">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="services">
            <div>
              <h1 className="experience-heading">Services</h1>
              <div className="experience-cards-div">
                <AliceCarousel
                  mouseTracking
                  autoHeight
                  infinite
                  autoPlay
                  disableButtonsControls
                  keyboardNavigation
                  autoPlayInterval={2000}
                  responsive={{
                    0: {
                      items: 3,
                    },
                    320: {
                      items: 1,
                    },
                    568: { items: 2 },
                    1024: { items: 3 },
                  }}
                  items={services.list.map((card, i) => {
                    return (
                      <ServiceCard key={i} isDark={isDark} cardInfo={card} />
                    );
                  })}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
