/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000, // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true, // Set to false to use static SVG
};

const greeting = {
  username: "Devops Beast",
  title1: "Transforming ",
  title2: "Enterprises with ",
  title3: "Cloud-Native ",
  title4: "Expertise",
  subTitle: emoji(
    "DevopsBeast helps our clients seize new opportunities through cutting edge Cloud solutions 🚀"
  ),
  resumeLink: "", // Set to empty to hide the button
  displayGreeting: true, // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  linkedin: "https://www.linkedin.com/company/devopsbeast/",
  twitter: "https://twitter.com/devopsbeast",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true, // Set true to display this section, defaults to false
};

// About Section

const aboutSection = {
  title: "Our Platform",
  subTitle: "Work With Tried and True Solution Architects",
  points: [
    emoji(
      "⚡ Our team's unique skills allow us to add value to every project we undertake. We have a broad range of knowledge needed to handle both simple and complex projects."
    ),
    emoji(
      "⚡ What would a business be without its clients? We develop partnerships with our clients through a consultative approach to driving business transformation while delivering innovative solutions."
    ),
    emoji(
      "⚡ Our main goal is to smooth out your DevOps journey so that you can focus on developing applications."
    ),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Kubernetes",
      img: require("./assets/images/kubernetes-icon.png"),
      darkImg: require("./assets/images/kubernetes-dark-icon.png"),
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker",
    },
    {
      skillName: "Cross plane",
      img: require("./assets/images/crossplane-icon.png"),
      darkImg: require("./assets/images/crossplane-dark-icon.png"),
    },
    {
      skillName: "Argo CD",
      img: require("./assets/images/argo-icon.png"),
      darkImg: require("./assets/images/argo-dark-icon.png"),
    },
    {
      skillName: "Flux CD",
      img: require("./assets/images/flux-icon.png"),
      darkImg: require("./assets/images/flux-dark-icon.png"),
    },
    {
      skillName: "Terraform",
      img: require("./assets/images/terraform-icon.png"),
      darkImg: require("./assets/images/terraform-dark-icon.png"),
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws",
    },
    {
      skillName: "GitHub Actions",
      img: require("./assets/images/githubactions-icon.png"),
      darkImg: require("./assets/images/githubactions-dark-icon.png"),
    },
    {
      skillName: "Go",
      img: require("./assets/images/go-icon.png"),
      darkImg: require("./assets/images/go-dark-icon.png"),
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python",
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

// HowItWork Section

const howItWorkInfo = {
  display: true, // Set false to hide this section, defaults to true
  process: [
    {
      title: "INTRODUCTION",
      logo: require("./assets/images/introduction.png"),
      darkLogo: require("./assets/images/introduction-dark.png"),
      descBullets: [
        "First, we talk and get to know each other",
        "Identify the challenges you face",
        "Learn more about your goals at a high level and delve into what challenges need to be overcome to achieve those goals",
      ],
    },
    {
      title: "TECHNICAL CALL",
      logo: require("./assets/images/collaboration.png"),
      darkLogo: require("./assets/images/collaboration-dark.png"),
      descBullets: [
        "Let’s explore some of your solution requirements, bringing both of our technical teams to the table",
        "At this stage, we can lay out exactly what you want with our team",
      ],
    },
    {
      title: "PROPOSAL",
      logo: require("./assets/images/proposal.png"),
      darkLogo: require("./assets/images/proposal-dark.png"),
      descBullets: [
        "This is essential for you to make a clear and informed decision",
        "This can be an iterative process and can involve a few conversations - If you need any changes, we are glad to discuss and make adjustments",
      ],
    },
    {
      title: "ACCEPTANCE",
      logo: require("./assets/images/acceptance.png"),
      darkLogo: require("./assets/images/acceptance-dark.png"),
      descBullets: [
        "In this step, we’ll formalize the plan, attach the legal information, and then return it to you to review and sign",
        "Following this, we will move on to onboarding",
      ],
    },
  ],
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%", //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%",
    },
    {
      Stack: "Programming",
      progressPercentage: "60%",
    },
  ],
  displayCodersrank: false, // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Services section

const services = {
  display: true, //Set it to true to show services Section
  list: [
    {
      title: "Comprehensive Kubernetes Ecosystem Consulting",
      imgSrc: require("./assets/images/consult.png"),
      details:
        "We will help you to get the most out of Kubernetes and make sure you avoid unnecessary issues and pitfalls.",
    },
    {
      title: "Streamlined CI/CD Pipeline Development",
      imgSrc: require("./assets/images/cloud.png"),
      details:
        "We help you migrate your applications to the cloud. Get your solution cloud-ready, one service at a time and host it wherever you want",
    },

    {
      title: "Terraform-Powered Infrastructure as Code Solutions",
      imgSrc: require("./assets/images/devops.png"),
      details:
        "We provide reliable solutions that meet your needs and accomplish the DevOps projects sitting in your backlog.",
    },

    {
      title: "Advanced GitOps Implementation Services",
      imgSrc: require("./assets/images/infrastructure.png"),
      details:
        "Design resilient and highly available cloud infrastructure to best suit your requirements.",
    },
    {
      title: "AWS Cloud adoption and Cost optimization",
      imgSrc: require("./assets/images/optimization.png"),
      details:
        "Right-size your cloud infrastructure to reduce the monthly infrastructure cost while maintaining optimal performance",
    },
  ],
};

// Some Testimonials

const testimonials = {
  title: "Testimonials",
  subtitle: "SOME STARTUPS AND COMPANIES THAT WE HELPED TO CREATE THEIR TECH",
  list: [
    {
      image: require("./assets/images/jon.png"),
      title: "Jonathan Bethune",
      subTitle: "Founder - Bethune Consulting | Dallas, TX, USA",
      review:
        "Bethune Consulting is a software development and cloud technology service firm based in Dallas. We serve a wide range of clients from international Fortune 100 companies to small local startups throughout the United States. We work with a number of expert contractors to meet our clients' needs. One such expert is Sharon Sahadevan. Since 2022 Sharon has been an integral part of our devops team writing top quality infrastructure code and solving complex operations challenges across a wide range of cloud providers. Sharon built Kubernetes clusters for a NYC blockchain business, optimized Dockerfiles for a billion dollar Japanese consumer electronics company, and set up virtual servers in AWS for a Dallas ed-tech startup. Thanks to a track record of excellent results Sharon remains on our shortlist of devops wizards whenever a new client challenge arises.",
    },
    {
      image: require("./assets/images/marcus.png"),
      title: "Marcus Rance",
      subTitle: "Co-Founder, Lockerverse | Los Angeles, CA, USA",
      review:
        "When Lockerverse faced the challenge of optimizing our AWS infrastructure costs, modernizing container workload management using Amazon ECS, designing a resilient and scalable RDS cluster infrastructure, and securing our infrastructure, we turned to Sharon Sahadevan for his expertise. Not only did Sharon rise to the challenge of improving our existing systems, but he also ensured that our infrastructure was replicable and manageable as code. Sharon's consultative approach was friendly and professional, and his willingness to go above and beyond was crucial in completing our project successfully and on time. Through his strategic cost-optimization initiatives, we saw a significant decrease in our AWS expenses, all while maintaining and, in many cases, improving system performance and reliability.",
    },
    {
      image: require("./assets/images/dom.png"),
      title: "Dominic Frazer-Imregh",
      subTitle: "CTO, Recrd | Dubai, UAE",
      review:
        "Sharon is an amazing engineer and architect. He has worked very closely with so many members of my team that it is good to know that they were so well supported. His direct approach to problem solving by reviewing the technology and costs as well as asking the most fundamental question of “why” has been refreshing and saved us a considerable amount of time and money.",
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Get in touch! ☎️"),
  subtitle: "Have a question? Concern? Request? We’d love to hear from you.",
  number: "",
  address: {
    line1: "2055 Limestone Rd STE 200-C,",
    line2: "Wilmington, DE 19808",
  },
  email_address: "info@devopsbeast.co",
};

// Twitter Section

const twitterDetails = {
  userName: "realdevopsbeast", //Replace "twitter" with your twitter username without @
  display: true, // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  aboutSection,
  howItWorkInfo,
  techStack,
  services,
  testimonials,
  contactInfo,
  twitterDetails,
  isHireable,
};
