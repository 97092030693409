import React, { createRef, useContext } from "react";
import { Fade, Slide } from "react-reveal";
import "./ProcessCard.scss";
import StyleContext from "../../contexts/StyleContext";

export default function ProcessCard({ process }) {
  const imgRef = createRef();
  const { isDark } = useContext(StyleContext);

  const GetDescBullets = ({ descBullets }) => {
    return descBullets
      ? descBullets.map((item, i) => (
          <li key={i} className="subTitle">
            {item}
          </li>
        ))
      : null;
  };

  if (!process.logo)
    console.error(
      `Image of ${process.name} is missing in How it works section`
    );
  return (
    <div>
      <Fade left duration={1000}>
        <div className="education-card">
          {process.logo && (
            <div className="education-card-left">
              <img
                crossOrigin={"anonymous"}
                ref={imgRef}
                className="education-roundedimg"
                src={isDark ? process.darkLogo : process.logo}
                alt={process.schoolName}
              />
            </div>
          )}
          <div className="education-card-right">
            <h5 className="education-text-school">{process.title}</h5>

            <div className="education-text-details">
              <div className="education-text-bullets">
                <ul>
                  <GetDescBullets descBullets={process.descBullets} />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Slide left duration={2000}>
        <div className="education-card-border"></div>
      </Slide>
    </div>
  );
}
