import React, { useContext } from "react";
import "./Testimonials.scss";
import { testimonials } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import AliceCarousel from "react-alice-carousel";

export default function Testimonials() {
  const { isDark } = useContext(StyleContext);
  if (!testimonials.display) {
    return null;
  }
  return (
    <div className="main" id="testimonials">
      <div>
        <h1 className="skills-heading">{testimonials.title}</h1>
        <p
          className={
            isDark ? "dark-mode project-subtitle" : "subTitle project-subtitle"
          }
        >
          {testimonials.subtitle}
        </p>
        <div className="projects-container">
          <AliceCarousel
            mouseTracking
            autoHeight
            infinite
            autoPlay
            disableButtonsControls
            keyboardNavigation
            autoPlayInterval={2000}
            responsive={{
              0: {
                items: 2,
              },
              320: {
                items: 1,
              },
              568: { items: 2 },
              1024: { items: 2 },
            }}
            items={testimonials.list.map((project, i) => {
              return (
                <div
                  key={i}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  {project.image ? (
                    <div className="project-image">
                      <img
                        crossOrigin={"anonymous"}
                        className="card-image"
                        src={project.image}
                        alt={project.title}
                      ></img>
                    </div>
                  ) : null}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.title}
                    </h5>
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.subTitle}
                    </h5>
                    <p
                      className={
                        isDark ? "dark-mode card-subtitle" : "card-subtitle"
                      }
                    >
                      {project.review}
                    </p>
                  </div>
                </div>
              );
            })}
          />
        </div>
      </div>
    </div>
  );
}
