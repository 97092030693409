import React from "react";
import "./HowItWork.scss";
import ProcessCard from "../../components/processCard/ProcessCard";
import { howItWorkInfo } from "../../portfolio";

export default function HowItWork() {
  if (howItWorkInfo.display) {
    return (
      <div className="education-section" id="how-it-works">
        <h1 className="education-heading">How We Work</h1>
        <h5 className="education-subheading">
          Our team's unique skills allow us to add value to every project we
          undertake. We have a broad range of knowledge needed to handle both
          simple and complex projects.
        </h5>
        <div className="education-card-container">
          {howItWorkInfo.process.map((e, index) => (
            <ProcessCard key={index} process={e} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
